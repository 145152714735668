<template>
  <b-card
    v-if="data"
    no-body
    :class="data.card.class"
  >
    <b-card-body style="padding-bottom:0">
      <b-row class="justify-content-center">
        <!-- chart -->
        <h5
          v-b-tooltip.hover.focus
          :class="data.title.class"
          :title="data.title.hover"
        >
          {{ data.name }}  <feather-icon
            icon="AlertCircleIcon"
            size="12"
          />
        </h5>
        <b-col
          sm="12"
          class="d-flex justify-content-center"
          style="margin-bottom:-45px; padding:0px;"
        >
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="180"
            :options="radialChartOptionsComputed"
            :series="data.radial.series"
          />
          <div
            class="change-indicator"
            :style="{color:data.radial.colorB}"
          >
            <feather-icon
              :icon="data.radial.change.icon"
              size="22"
            />
          </div>
        </b-col>
        <b-col
          sm="12"
          style=" font-size: 0.7rem; margin-top:2rem"
        >
          {{ data.text.p1 }}
          <div
            style=" font-size: 2rem"
          >{{ data.text.percentage }}</div>
          {{ data.text.p2 }}
        </b-col>
        <!--/ chart -->
      </b-row>
      <!-- chart info -->
    </b-card-body>
    <vue-apex-charts
      type="area"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="data.graph.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { areaChartOptions } from '@core/components/statistics-cards/chartOptions'
import radialChartOptions from './chartOptions'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(areaChartOptions))
      options.theme.monochrome.color = this.data.graph.color
      options.grid.padding.top = 10
      options.annotations = {
        xaxis: [{
          strokeDashArray: 5,
          x: 3.75,
          borderColor: '#999999',
        },
        {
          strokeDashArray: 5,
          x: 6.25,
          borderColor: '#999999',
        },
        ],
      }
      const { scoreName } = this.data
      options.xaxis = {
        labels: {
          formatter(val) {
            // todo - define low/high/neutral based on val
            let level = 'Neutral'
            if (val < 4) {
              level = 'Low'
            } else if (val > 6) {
              level = 'High'
            }

            return `A ${scoreName} Score of ${val} (${level})`
          },
        },
      }
      options.tooltip = {
        x: { show: true },
      }
      options.yaxis = {
        labels: {
          formatter(val) {
            return val.toFixed(0)
          },
        },
      }
      return options
    },
    radialChartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(radialChartOptions))
      options.colors = [this.data.radial.colorB]
      options.fill.gradient.gradientToColors = [this.data.radial.colorA]
      options.plotOptions.radialBar.track.background = [this.data.radial.colorBG]
      options.plotOptions.radialBar.dataLabels.value.color = this.data.radial.colorText
      /* eslint func-names: ["error", "never"] */
      options.plotOptions.radialBar.dataLabels.value.formatter = function (val) {
        return Math.round(val * 100) / 1000
      }
      // console.log(options)
      return options
    },
  },
}
</script>
