<template>
  <b-card
    v-if="data"
    text-variant="left"
    class="card card-survey"
  >
    <b-avatar
      variant="primary"
      size="60"
      class="shadow aequip-card-top-right mb-2"
    >
      <feather-icon
        size="28"
        icon="SettingsIcon"
      />
    </b-avatar>
    <b-card-header class="aequip-card-header">
      <h1 class="mb-sm-0 text-b-yellow">
        Getting started with Aequip
      </h1>

    </b-card-header>
    <b-card-body class="no-padding-bottom">
      <b-card-text>
        <p>Hi {{ getFirstName }}, welcome to your Aequip Dashboard!</p>
        <p>Here's what you can do to get your organisation started on the platform:<br>
          <ol>
            <li class="padding-top"> Add your employees to the system in the <b-link @click="$router.push({ name: 'manage-users' })">
              Manage Users
            </b-link> section. You can choose to do this manually or in bulk through the Import User Spreadsheet function.</li>
            <li class="padding-top">
              Manage user permissions. As an admin for your organisation, you can grant other people admin access. You can always change these permissions later too.
            </li>
            <li class="padding-top"> Once you've registered your employees, go to the <b-link @click="$router.push({ name: 'actions' })">
              Actions
            </b-link> section and activate your first Inclusion Index Survey. We'll walk you through a few simple steps to get the survey set up.</li>
          </ol></p>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardText, BCardBody, BCardHeader, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BAvatar,
    BCardText,
    BCardBody,
    BCardHeader,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getFirstName() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData.given_name
    },
  },
}
</script>
